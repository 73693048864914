import { RSAA } from 'redux-api-middleware';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import { requestHeaders } from '../../utils/api';
import { setNotice } from '../UI/actions';
import * as actions from './actions';
import * as Types from './types';
import {
  getAccountTransactions,
  getAllAccountTransactions,
  getTransactionsHistory,
  updateTransaction,
} from '../Transaction/operations';
import { fetchRoomById, updateRoom } from '../Data/operations';

export const getAccountProjects = moduleId => {
  let url = '/api/account/project';
  if (moduleId) {
    url = url + `?module_id=${moduleId}`;
  }
  const REQUEST = Types[`GET_ACCOUNT_PROJECTS_REQUEST`];
  const SUCCESS = Types[`GET_ACCOUNT_PROJECTS_SUCCESS`];
  const FAILED = Types[`GET_ACCOUNT_PROJECTS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: url,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(actions.setAccountProjects(actionResponse.payload));
      // await dispatch(actions.setQuestionnaire(actionResponse.payload));
    }
  };
};

export const deleteAccountProject = id => {
  const REQUEST = Types[`DELETE_ACCOUNT_PROJECT_REQUEST`];
  const SUCCESS = Types[`DELETE_ACCOUNT_PROJECT_SUCCESS`];
  const FAILED = Types[`DELETE_ACCOUNT_PROJECT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/project/${id}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice({ message: 'Project Deleted' }));
      return await actionResponse.payload;
      // return await dispatch(actions.setAccountProject(actionResponse.payload));
    } else {
      if (
        actionResponse.payload.message !==
        'Project contains completed transactions.  Please contact paperOS support.'
      ) {
        await dispatch(setNotice({ message: actionResponse.payload.message }));
      }
      throw actionResponse.payload;
    }
  };
};

export const getAccountProject = id => {
  const REQUEST = Types[`GET_ACCOUNT_PROJECT_REQUEST`];
  const SUCCESS = Types[`GET_ACCOUNT_PROJECT_SUCCESS`];
  const FAILED = Types[`GET_ACCOUNT_PROJECT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/project/${id}`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error && !isEmpty(actionResponse.payload)) {
      // return actionResponse.payload;
      let { attached_partners = [], rooms = [], template = {} } = actionResponse.payload;

      await dispatch(actions.setAccountProject(actionResponse.payload));

      if (rooms.length > 0) {
        // const reportingRoomIndex = rooms.findIndex(room => room.group === 'Reporting');
        const isPortalView = (template?.actions || []).length > 0;

        // if (reportingRoomIndex !== -1) {
        //   if (rooms[reportingRoomIndex].type !== 'report') {
        //     const reportingRoom = rooms[reportingRoomIndex];
        //     const { id, account_id } = reportingRoom;
        //     rooms[reportingRoomIndex] = { ...reportingRoom, type: 'report' };
        //     await dispatch(updateRoom({ type: 'report' }, id, account_id, null));
        //   } else {
        //     await dispatch(fetchRoomById(rooms[reportingRoomIndex].id));
        //   }
        // }
        if (isPortalView) {
          let shouldFetchUpdatedProject = false;
          for (let room of rooms) {
            let shouldUpdateRoom = false;
            let body = {};
            if (attached_partners.length > 0 && !room.partner_id) {
              shouldFetchUpdatedProject = true;
              shouldUpdateRoom = true;
              body['partner_id'] = attached_partners[0].partner_id;
            }
            if (!room.type && !!room.group && room.group !== 'Reporting') {
              shouldFetchUpdatedProject = true;
              shouldUpdateRoom = true;
              body['type'] = 'deal';
            } else if (room.group === 'Reporting') {
              if (room.type !== 'report') {
                shouldFetchUpdatedProject = true;
                shouldUpdateRoom = true;
                body['type'] = 'report';
              } else {
                await dispatch(fetchRoomById(room.id));
              }
            }
            if (shouldUpdateRoom) {
              await dispatch(updateRoom(body, room.id, room.account_id, null, false));
            }
          }
          if (shouldFetchUpdatedProject) {
            return await dispatch(getAccountProject(id));
          }
        }
      }
      return await actionResponse.payload;
    }
    throw await actionResponse.payload;
  };
};

export const updateProject = (body, projectId) => {
  const REQUEST = Types[`UPDATE_PROJECT_REQUEST`];
  const SUCCESS = Types[`UPDATE_PROJECT_SUCCESS`];
  const FAILED = Types[`UPDATE_PROJECT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/project/${projectId}`,
        method: 'PUT',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
      // return await dispatch(actions.setProjects(actionResponse.payload));
    }
    throw await get(actionResponse, 'payload.message', 'An error has occurred.');
  };
};

export const getProjects = moduleId => {
  let url = '/api/account/project_template';
  if (moduleId) {
    url = url + `?module_id=${moduleId}`;
  }
  const REQUEST = Types[`GET_PROJECTS_REQUEST`];
  const SUCCESS = Types[`GET_PROJECTS_SUCCESS`];
  const FAILED = Types[`GET_PROJECTS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: url,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(actions.setProjects(actionResponse.payload));
    }
    throw await get(actionResponse, 'payload.message', 'An error has occurred.');
  };
};

export const getProject = projectId => {
  const REQUEST = Types[`GET_PROJECT_REQUEST`];
  const SUCCESS = Types[`GET_PROJECT_SUCCESS`];
  const FAILED = Types[`GET_PROJECT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/project_template/:1`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      // await dispatch(actions.setQuestionnaire(actionResponse.payload));
    } else {
      throw await get(actionResponse, 'payload.message', 'An error has occurred.');
    }
  };
};

export const postProject = id => {
  const REQUEST = Types[`POST_PROJECT_REQUEST`];
  const SUCCESS = Types[`POST_PROJECT_SUCCESS`];
  const FAILED = Types[`POST_PROJECT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/project_template/${id}/create`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(getAccountProject(actionResponse.payload.id));
    } else {
      await dispatch(
        setNotice({ type: 'error', message: actionResponse.payload.message }),
      );
      throw await actionResponse.payload;
    }
  };
};

export const performTaskAction = (action, taskId, projectId, parentView) => {
  const REQUEST = Types[`PERFORM_TASK_ACTION_REQUEST`];
  const SUCCESS = Types[`PERFORM_TASK_ACTION_SUCCESS`];
  const FAILED = Types[`PERFORM_TASK_ACTION_FAILED`];
  let method = 'PUT';
  let endpoint = `/api/account/task/${taskId}`;
  if (action === 'reopen') {
    method = 'PATCH';
    endpoint = `/api/account/transaction/${taskId}/reopen`;
  } else if (action === 'delete') {
    method = 'DELETE';
    endpoint = `/api/account/transaction/${taskId}`;
  }
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method,
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify({ action }),
      },
    });
    if (!actionResponse.error && !isEmpty(actionResponse.payload)) {
      const message =
        (action === 'delete' && 'Task deleted.') ||
        (action === 'Generate' && 'Process set to generate document.') ||
        (action === 'Upload' && 'Process set to upload document.') ||
        (action === 'Bypass' && 'Process set to skip document.') ||
        (action === 'Enable' && 'Process enabled.') ||
        (action === 'Custom' && 'Process set to custom document.') ||
        (action === 'reopen' && 'Task reopened.') ||
        'Requested action submitted.';
      if (parentView === 'Tasks') {
        await dispatch(getTransactionsHistory('tasks'));
      } else if (parentView === 'Home') {
        await dispatch(getAccountTransactions('-1'));
      } else if (parentView === 'Portal') {
        return await actionResponse.payload;
      } else if (parentView === 'MyDashboard') {
        await dispatch(getAllAccountTransactions());
      } else if (!!projectId) {
        await dispatch(getAccountProject(projectId));
      }
      if (action === 'reopen') {
        await dispatch(updateTransaction(taskId, { validated_questionnaire: 0 }));
      }
      await dispatch(
        setNotice({ type: action === 'delete' ? 'warning' : 'success', message }),
      );
      return await actionResponse.payload;
    }
    await dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'An error has occurred. if error persists, please contact PaperOS Support.',
        ),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'An error has occurred.');
  };
};

export const updateTaskVariant = (account_task_id, body, projectId) => {
  return async (dispatch, getState) => {
    const REQUEST = Types[`UPDATE_TASK_VARIANT_REQUEST`];
    const SUCCESS = Types[`UPDATE_TASK_VARIANT_SUCCESS`];
    const FAILED = Types[`UPDATE_TASK_VARIANT_FAILED`];
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/task/${account_task_id}`,
        method: 'PUT',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(
        setNotice({
          message: get(actionResponse, 'payload.message', 'Task Template Updated.'),
        }),
      );
      await dispatch(getAccountProject(projectId));
      return await actionResponse.payload;
      // dispatch(actions.patchFeature(actionResponse.payload, individualName));
      // dispatch(
      //   setNotice(`${individualName}'s ${lowerCase(featureName)} updated.`),
      // );
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: get(
            actionResponse,
            'payload.message',
            'An error has occurred updating task template.',
          ),
        }),
      );
      throw await actionResponse.payload;
    }
  };
};

export const updateProjectCounsel = (initialCounsel, chosenCounsel, projectId) => {
  return async (dispatch, getState) => {
    if (!!initialCounsel) {
      return await dispatch(
        deleteProjectCounsel(initialCounsel.id, projectId, chosenCounsel),
      );
    } else {
      return await dispatch(addProjectCounsel(chosenCounsel, projectId));
    }
  };
};

const deleteProjectCounsel = (counselId, projectId, counselToAdd) => {
  const REQUEST = Types[`DELETE_PROJECT_COUNSEL_REQUEST`];
  const SUCCESS = Types[`DELETE_PROJECT_COUNSEL_SUCCESS`];
  const FAILED = Types[`DELETE_PROJECT_COUNSEL_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/project/${projectId}/counsel/${counselId}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      if (!isEmpty(counselToAdd)) {
        return await dispatch(addProjectCounsel(counselToAdd, projectId));
      }
      return await dispatch(getAccountProject(projectId));
      // return await dispatch(actions.setProjects(actionResponse.payload));
    }
    throw await get(actionResponse, 'payload.message', 'An error has occurred.');
  };
};

const addProjectCounsel = (counsel, projectId) => {
  const REQUEST = Types[`ADD_PROJECT_COUNSEL_REQUEST`];
  const SUCCESS = Types[`ADD_PROJECT_COUNSEL_SUCCESS`];
  const FAILED = Types[`ADD_PROJECT_COUNSEL_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/project/${projectId}/counsel`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(counsel),
      },
    });
    if (!actionResponse.error) {
      return await dispatch(getAccountProject(projectId));
      // return await dispatch(actions.setProjects(actionResponse.payload));
    }
    throw await get(actionResponse, 'payload.message', 'An error has occurred.');
  };
};

// export const getTransactionQuestionnaire = (questionnaireId, taskId) => {
//   const REQUEST = Types[`GET_TRANSACTION_QUESTIONNAIRE_REQUEST`];
//   const SUCCESS = Types[`GET_TRANSACTION_QUESTIONNAIRE_SUCCESS`];
//   const FAILED = Types[`GET_TRANSACTION_QUESTIONNAIRE_FAILED`];
//   return async (dispatch, getState) => {
//     const actionResponse = await dispatch({
//       [RSAA]: {
//         endpoint: `/api/account/transaction/1/questionnaire`,
//         method: 'GET',
//         headers: requestHeaders(true),
//         types: [REQUEST, SUCCESS, FAILED],
//       },
//     });
//     if (!actionResponse.error) {
//       // await dispatch(actions.setQuestionnaire(actionResponse.payload));
//     }
//   };
// };
